App.Dashboard = (function() {

	var refreshXHQ = null;

	var $overview = $('.stats.overview .contents');
	var $weeklyByForms = $('.stats.this-week.forms .contents');
	var $weeklyBySources = $('.stats.this-week.sources .contents');
	var $weeklyByConversions = $('.stats.this-week.conversions .contents');

	var charts = {

		byForms: {
			graph: null,
			canvas: null,

			canvasID: 'chart-by-forms',

			$graph: $('.chart-this-week-forms .graph'),
			$legend: $('.chart-this-week-forms .legend')
		},

		bySources: {
			graph: null,
			canvas: null,

			canvasID: 'chart-by-sources',

			$graph: $('.chart-this-week-sources .graph'),
			$legend: $('.chart-this-week-sources .legend')
		},

		byConversions: {
			graph: null,
			canvas: null,

			canvasID: 'chart-by-conversions',

			$graph: $('.chart-this-week-conversions .graph'),
			$legend: $('.chart-this-week-conversions .legend')
		}

	};

	var $loading = $('.loading');

	function refreshWeeklyStats() {

		$loading.show();

		if(refreshXHQ) {
			refreshXHQ.abort();
		}

		refreshXHQ = $.ajax({
			url: App.route('panel/stats/last_week'),
			dataType: 'json',
			success: function(stats) {
				refreshXHQ = null;
				$loading.hide();

				renderWeeklyStats(stats);
				renderWeeklyChart(charts.byForms, "leadsByForms", "forms", stats);
				renderWeeklyChart(charts.bySources, "leadsBySources", "sources", stats);
				renderWeeklyChart(charts.byConversions, "leadsByConversions", "referrers", stats, "url");
			}
		});

	}

	function renderWeeklyStats(stats) {

		console.log("[app.dashboard] Rendering weekly stats: ", stats);

		// Maps an array of forms by their ID
		var formMap = {};
		for(var i in stats.this_week.forms) {
			var form = stats.this_week.forms[i];
			formMap[form.id] = form;
			formMap[form.id].week_total = 0;
		}

		// Gets the total sum of leads in a week by form
		for(var date in stats.this_week.leadsByForms) {
			var counts = stats.this_week.leadsByForms[date];

			for(var formID in counts) {
				formMap[formID].week_total += counts[formID];
			}

		}

		var overviewHTML = App.render('tpl-overview', stats.overview);
		$overview.html(overviewHTML);

		var byFormsHTML = App.render('tpl-last-week-forms', stats.this_week);
		$weeklyByForms.html(byFormsHTML);

		var bySourcesHTML = App.render('tpl-last-week-sources', stats.this_week);
		$weeklyBySources.html(bySourcesHTML);

		var byConversionsHTML = App.render('tpl-last-week-conversions', stats.this_week);
		$weeklyByConversions.html(byConversionsHTML);

	}

	function generateChartDatasetColor(dataset) {

		var base = {
			r: Math.ceil(Math.random() * 255),
			g: Math.ceil(Math.random() * 255),
			b: Math.ceil(Math.random() * 255)
		};

		var baseStr = base.r + "," + base.g + "," + base.b;

		dataset.fillColor = "rgba("+baseStr+",0.2)";
		dataset.strokeColor = "rgba("+baseStr+",0.2)";
		dataset.pointColor = "rgba("+baseStr+",0.2)";
		dataset.pointStrokeColor = "#fff";
		dataset.pointHighlightFill = "#fff";
		dataset.pointHighlightStroke = "rgba("+baseStr+",0.2)";

	}

	function renderWeeklyChart(chart, countKey, parentKey, stats, labelKey) {

		console.log("[app.dashboard] Rendering chart: ", chart);

		if(!labelKey) {
			labelKey = "name";
		}

		var chartW = chart.$graph.innerWidth() - 20;
		var chartH = chart.$graph.innerHeight() - 20;

		chart.$graph.html('<canvas id="' + chart.canvasID + '" width="' + chartW + '" height="' + chartH + '"></canvas>');

		chart.canvas = chart.$graph.find('canvas').get(0);
		var ctx = chart.canvas.getContext('2d');

		var datasets = [];

		for(var i in stats.this_week[parentKey]) {
			var parent = stats.this_week[parentKey][i];
			var dataset = {
				label: parent[labelKey],
				data: []
			};

			for(var j in stats.this_week.dates) {
				var date = stats.this_week.dates[j];
				var dateParents = stats.this_week[countKey][date];

				if(!dateParents) {
					dataset.data[j] = 0;
					continue;
				}

				var count = stats.this_week[countKey][date][parent.id];
				dataset.data[j] = (count) ? count : 0;
			}

			generateChartDatasetColor(dataset);

			datasets[i] = dataset;

		}

		var data = {
			labels: stats.this_week.dates,
			datasets: datasets
		};

		var options = {

		};

		chart.graph = new Chart(ctx).Line(data, options);
		chart.$legend.html(chart.graph.generateLegend());
	}

	function init() {
		refreshWeeklyStats();
	}

	init();

});

App.DataIO = (function() {

	var formID = null;
	var beforeDate = "today";
	var afterDate = null;

	var previewRequest = null;

	var $formID = $('#fld-form-id');

	var $beforeMode = $('#fld-before-mode');
	var $afterMode = $('#fld-after-mode');

	var $beforeDate = $('#fld-before-date');
	var $afterDate = $('#fld-after-date');

	var $count = $('span.count');
	var $exportBtn = $('#btn-do-export');
	var $exportForm = $('#frm-export');

	var $loading = $('.loading');

    $btnClearAll = $('#btn-clear-all');
    $btnCheckAll = $('#btn-check-all');

	function handleDateChange($mode, $date) {

		$date.hide();

		switch($mode.val()) {
			case "today":
				return "today";

			case "one_week_ago":
			case "one_month_ago":
				return $mode.find('option:selected').data('date');

			case "date":
				$date.show();
				return $date.val();
		}

	}

	function onBeforeChange() {
		beforeDate = handleDateChange($beforeMode, $beforeDate);
		updatePreview();
	}

	function onAfterChange() {
		afterDate = handleDateChange($afterMode, $afterDate);
		updatePreview();
	}

	function onFormChange() {
		formID = $formID.val();
		updatePreview();
	}

	function getExportCSVPath() {
		return App.route('panel/data_io/export_csv', formID, beforeDate, afterDate);
	}

	function updatePreview() {
		console.log('[app.data_io] Updating count preview...');
		$loading.addClass('is-loading');

		$exportBtn.hide().attr('disabled', 'disabled');

		if(previewRequest) {
			console.warn('[app.data_io] Aborting previous request');
			previewRequest.abort();
		}

		previewRequest = $.ajax({
			url: App.route('panel/data_io/preview', formID, beforeDate, afterDate),
			dataType: 'json',
			success: function (data) {
				console.log('[app.data_io] New count: ', data.count);
				$count.text(data.count);

				$exportBtn.show();

				if(data.count > 0) {
                    $exportForm.attr('action', getExportCSVPath())
					$exportBtn.removeAttr('disabled');
				}

				previewRequest = null;
				$loading.removeClass('is-loading');
			}
		})
	}

	function exportFrmSubmit() {
        $('.columns input[type="checkbox"]').each(function() {
        	var item = this;
        	var column = $(item).attr('value');
        	var is_checked = $(item).prop('checked');
        	window.localStorage.setItem(column, is_checked);
		});
	}

	function onDocReady() {
        $('.columns input[type="checkbox"]').each(function() {
        	var item = this;
            var column = $(item).attr('value');
            console.log(column);
            var is_checked = JSON.parse(window.localStorage.getItem(column));

            if (is_checked !== null)
                $(item).prop('checked', is_checked);
        });
	}

	function onClickClearAll() {
        $('.columns input').prop('checked', false);
	}

	function onClickCheckAll() {
        $('.columns input').prop('checked', true);
	}

	function init() {
		console.log('[app.data_io] Module initialized');

		onFormChange();

		$formID.on('change', onFormChange);
		$beforeMode.on('change', onBeforeChange);
		$beforeDate.on('change', onBeforeChange).hide();
		$afterMode.on('change', onAfterChange);
		$afterDate.on('change', onAfterChange).hide();

        $exportForm.on('submit', exportFrmSubmit);

        $(document).ready(onDocReady);
        $btnClearAll.on('click', onClickClearAll);
        $btnCheckAll.on('click', onClickCheckAll);
	}

	init();

});
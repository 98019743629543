App.Tools.LinkGenerator = (function() {

	function generateFormPostURL(formID, sourceID, targetID) {

		sourceID = (sourceID) ? sourceID : 0;
		targetID = (targetID) ? targetID : 0;

		var route = 'submit/' + formID + '/' + sourceID + '/' + targetID;

		return App.baseURL + route;

	}

	function generateSourceTaggedURL(url, sourceID, utmSource, utmCampaign, utmMedium, utmContent, utmTerm) {

		var params = {};

		if(sourceID) params.slms_sourceid = sourceID;
		if(utmSource) params.utm_source = utmSource;
		if(utmCampaign) params.utm_campaign = utmCampaign;
		if(utmMedium) params.utm_medium = utmMedium;
		if(utmContent) params.utm_content = utmContent;
		if(utmTerm) params.utm_term = utmTerm;

		var query = $.param(params);

		var glue = (url.indexOf('?') !== -1) ? '&' : '?';

		return url + glue + query;

	}

	function init() {
		console.log('[app.tools.linkgenerator] Module initialized');

		$('.result').hide();

		$('#form_post_link-generate').on('click', function(ev) {

			var formID = $('#form_post_link-form-id').val();
			var targetID = $('#form_post_link-target-id').val();

			var result = generateFormPostURL(formID, 0, targetID);

			$('#form_post_link-result').val(result);

			$('.form-post-link .result').show();

		});

		$('#source_tagged_url-generate').on('click', function(ev) {

			var url = $('#source_tagged_url-url').val();
			var utmSource = $('#source_tagged_url-utmSource').val();
			var utmCampaign = $('#source_tagged_url-utmCampaign').val();
			var utmMedium = $('#source_tagged_url-utmMedium').val();
			var utmContent = $('#source_tagged_url-utmContent').val();
			var utmTerm = $('#source_tagged_url-utmTerm').val();
			var sourceID = parseInt($('#source_tagged_url-source-id').val());

			var result = generateSourceTaggedURL(url, sourceID, utmSource, utmCampaign, utmMedium, utmContent, utmTerm);

			$('#source_tagged_url-result').val(result);

			$('.source-tagged-url .result').show();

		});

	}

	init();

});
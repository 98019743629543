App.setupHelpers = function() {

	Handlebars.registerHelper('renderWeekDay', function(date) {
		return moment(date).format('ddd, DD/MM');
	});

	Handlebars.registerHelper('toInt', function(num) {
		return (num) ? parseInt(num, 10) : 0;
	});

	Handlebars.registerHelper('renderStatsTD', function(array, indexA, indexB) {

		if(!array) return renderStatsTD(0);
		if(!array[indexA]) return renderStatsTD(0);

		if(indexB) {
			if(!array[indexA][indexB]) return renderStatsTD(0);
			return renderStatsTD(array[indexA][indexB]);
		}

		return renderStatsTD(array[indexA]);

	});

	Handlebars.registerHelper('debug', function(v) {
		console.log(v);
	});

	Handlebars.registerHelper('createCanvas', function(elmID, elmW, elmH) {
		return '<canvas id="' + elmID + '" width="'+ elmW + '" height="' + elmH + '" />'
	});

	Handlebars.registerHelper('renderInternalURL', function(url) {
		return renderInternalURL(url);
	});

};

function renderStatsTD(val) {
	return '<td class="'+((val <= 0) ? 'zero' : '')+'">' + val + '</td>';
}

function renderInternalURL(url) {
	return url.substring(url.indexOf('/', 7));
}

function str_replace(search, replace, subject, count) {
	var i = 0,
		j = 0,
		temp = '',
		repl = '',
		sl = 0,
		fl = 0,
		f = [].concat(search),
		r = [].concat(replace),
		s = subject,
		ra = Object.prototype.toString.call(r) === '[object Array]',
		sa = Object.prototype.toString.call(s) === '[object Array]';
	s = [].concat(s);
	if (count) {
		this.window[count] = 0;
	}

	for (i = 0, sl = s.length; i < sl; i++) {
		if (s[i] === '') {
			continue;
		}
		for (j = 0, fl = f.length; j < fl; j++) {
			temp = s[i] + '';
			repl = ra ? (r[j] !== undefined ? r[j] : '') : r[0];
			s[i] = (temp)
				.split(f[j])
				.join(repl);
			if (count && s[i] !== temp) {
				this.window[count] += (temp.length - s[i].length) / f[j].length;
			}
		}
	}
	return sa ? s : s[0];
}